import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const TowelFriendlySauna = () => (
  <Layout>
    <SEO title="Towel Friendly Sauna" />
    <h1>Why a good mixed sauna is towel-friendly, and why many are not</h1>

      <p>Wearing a swimsuit reduces the benefits of a sauna. So if you know what’s good for you, you’ll avoid
          wearing one. Covering yourself loosely with a towel is sufficient for any modesty concerns you or your
          fellow bathers may have.</p>
      <p>So, any mixed sauna that doesn’t explicitly say “swimwear must be worn” must be suitable for use in just a
          towel, right?</p>
      <p>Not quite. To be truly towel-friendly, a sauna facility needs a showering facility with<br />
          1) some privacy<br />
          and<br />
          2) somewhere to hang a towel</p>
      <p>What this means in practice is a shower cubicle. But for some reason these are rare.</p>
      <p>There is a growing trend for spiral walk-in showers. These offer sufficient privacy to protect any modesty
          but fail to provide anywhere to hang or place a towel – often there’s not even any bit of floor that has
          privacy screening AND remains dry! This leaves the towel wearer who wants to shower with a poor choice – a
          wet towel, a brief moment of exposure, or a trip back to the changing rooms?</p>
      <p>The other common sight is a row of showers along a wall, often used with pool-side facilities. Some of
          these can be ridiculously overlooked. I’ve seen one with a glass wall, and another that was positioned so
          that anyone in the car park could see anyone that was showering.</p>
      <p>As the cool-down and shower is a key part of most sauna rituals, a sauna that is not towel-friendly is a
          bit of a pain. It also discourages proper showering between facilities.</p>
      <p>An open thermal suite layout may work perfectly well in countries where exposed nudity is expected in the
          sauna area, but in more modest cultures additional thought needs to be given.</p>
      <p>This means that any good towel-friendly sauna is to be celebrated. That’s why we’re introducing a special
          badge for our listings.</p>
      <blockquote><p>
          Good article! There are far too many members of the “swimsuit mafia” out there advocating full coverage at
          all times. It is nice to find someone else who values the ability to find a sauna one can enjoy as God
          intended.</p></blockquote>
      <p>Thank you. Sauna should be enjoyable, not a hassle – but that doesn’t mean taking the easy route of
          swimwear. Full swimsuit coverage is a lazy solution to the modesty issue, and a lazy approach to
          hygiene.</p>
      <p>I guess I’m advocating simple concealment as the alternative to full coverage. I just wish more venues
          considered this approach.</p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default TowelFriendlySauna
